<template lang="pug">
.kyc-no-verification-container
  img.info-icon(src="/icons/icon-info-no-access.svg")
  h1 Доступ ограничен
  p Чтобы получить доступ ко всем возможностям, необходимо пройти верификацию личности
  .profile-kyc-status(v-if="isPending")
    img.icon(src="/awesome-icons/waiting.svg")
    | На проверке
  button.btn.btn-success(
    v-else
    @click="isKycDialogOpened = true"
  ) Пройти верификацию
  DialogProfileVerification(
    v-if="isKycDialogOpened"
    :opened="isKycDialogOpened"
    @close="isKycDialogOpened = false"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import DialogProfileVerification from './Dialogs/DialogProfileVerification.vue';

export default {
  name: 'KycNoVerificationMessage',
  components: {
    DialogProfileVerification,
  },
  data() {
    return {
      isKycDialogOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),
    isPending() {
      return this.profile.kyc && this.profile.kyc.is_pending;
    },
  }
};
</script>

<style lang="scss" scoped>
.kyc-no-verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.info-icon {
  width: 56px;
  height: 56px;
  margin-bottom: 1rem;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  color: $gray800;
  margin-bottom: 0.6875rem;
}

p {
  max-width: 350px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: $gray600;
  margin-bottom: 1.125rem;

  &:last-of-type {
    margin-bottom: 1.5625rem;
  }
}

.status-pending {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 4px;

  margin: 11px 16px;

  border: 2px solid $blue300;
  border-radius: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $blue600;
}
</style>
