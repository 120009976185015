<template lang="pug">
  .pt-3
    .create-claim
      el-form(
        :model="data"
        ref="form"
        class='form'
        label-width="150px"
        :rules="rules"
      )
        el-form-item(label="ID сделки" prop='id' required)
          el-input(v-model="data.id" type='number')
        el-form-item(label="Сумма" prop='sum' required)
          el-input(v-model="data.sum" type='number')
        el-form-item(label="Комментарий" prop='comment' required)
          el-input(v-model="data.comment" resize='none' type='textarea' :rows="6" :minlength="5" :maxlength="1500" show-word-limit)
        el-form-item(label="Изображения" prop='images')
          el-upload(
            action="",
            class='upload',
            ref="upload",
            :http-request="loadImage",
            :before-upload="beforeUpload",
            :before-remove="beforeRemove",
            :on-remove="removeChosenFile",
            :limit=3,
            :auto-load="false",
          )
            el-button(type='primary' :disabled="image_count >= 3") Выбрать изображения
        el-alert(
          type="warning"
          class="pb-3 alert-text"
          title='Примечание'
          :closable="false"
        ) Претензии позволяют решать вопросы по сделкам между пользователями без привлечения технической поддержки,&nbsp;
          |если вам необходима помощь, тогда напишите на нашу почту <a href="mailto:support@finex.market">support@finex.market</a>.
          |В случае положительного ответа на претензию, указанная сумма будет переведена на ваш аккаунт.
        el-button(type='primary' @click="onSubmit('form', data)" class="claim-btn") Создать претензию
</template>

<script>
  import {mapActions} from "vuex";
  import {CREATE_CLAIM} from "@/store/actions/claims";
  import cfg from "../../../config";

  export default {
    data() {
      return {
        data: {
          id: '',
          sum: null,
          comment: null
        },
        image_count: 0,
        form: new FormData(),
        selected_files: {},
        rules: {
          id: [{
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('Введите ID сделки'));
              else callback()
            }}],
          sum: [{
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('Введите сумму сделки'));
              else callback()
            }}],
          comment: [{
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('Комментарий обязателен'));
              if (value.trim().length < 5) return callback(new Error('В комментарии должно быть минимум 5 символов.'));
              else callback()
            }}]
        }
      }
    },
    methods: {
      ...mapActions({createClaim: CREATE_CLAIM}),
      loadImage(data) {
        this.form.append(`images[${this.image_count}]`, data.file);
        this.selected_files[data.file.name] = this.image_count;
        this.image_count++;
      },
      beforeUpload(file) {
        let types = [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/gif',
        ];
        if (types.indexOf(file.type) === -1 || file.size > parseInt(cfg.claim_max_filesize)) {
          this.$message.error('Изображение может быть только jpg/png/gif и не больше 3 МБ');
          return false;
        }
        return true;
      },
      beforeRemove() {
        this.image_count--;
      },
      removeChosenFile(file) {
        let index = this.selected_files[file.name];
        this.form.delete(`images[${index}]`)
      },
      onSubmit(formName, claim) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm(`Вы действительно хотите создать претензию по сделке № ${claim.id} сделки на сумму ${claim.sum}?`, `Внимание`, {
              confirmButtonText: 'Да',
              cancelButtonText: 'Нет',
              type: 'warning'
            }).then(() => {
              if (!this.form.get('deal_id') || this.form.get('deal_id') !== this.data.id) {
                this.form.delete('deal_id');
                this.form.append('deal_id', this.data.id);
              }
              if (!this.form.get('claim') || this.form.get('claim') !== this.data.comment) {
                this.form.delete('claim');
                this.form.append('claim', this.data.comment);
              }
              if (!this.form.get('fiat_amount') || this.form.get('fiat_amount') !== this.data.sum) {
                this.form.delete('fiat_amount');
                this.form.append('fiat_amount', this.data.sum);
              }
              this.createClaim(this.form).then(() => {
                this.$router.push({ name: 'Claims' });
              }).catch((error) => {
                if(error && error.errors){
                  let message = '';
                  for (const error_type in error.errors) {
                    for (const errorItem of error.errors[error_type]) {
                      message += `${errorItem}<br>`
                    }
                  }
                  this.$message.error({
                    dangerouslyUseHTMLString: true,
                    message
                  });
                }
              })
            })
          } else {
            console.log('error submit!!!');
          }
        });
      }
    }
  }
</script>

<style lang="scss">
  .create-claim {
    width: 80%;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.148211);
    border-radius: 8px 8px 0 0;
    margin: 0 auto 0;
    padding: 20px;
    background: white;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .claim-btn {
    margin-left: auto;
    margin-top: 20px;
    width: 180px
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .el-alert__description > a {
    color: #E6A23C;
  }
</style>
