<template lang="pug">
.main-content.kyc-no-confirmed-message.layout-main__centering.container.full-height(
  v-if="mustGoThroughKyc"
  v-loading="isProfileLoading"
)
  KycNoVerificationMessage
.main-content.create-offer.layout-main__centering.container.pb-5(v-else)
  CreateClaimComponent
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_PROFILE } from '../store/actions/user';
import CreateClaimComponent from '../components/Claims/CreateClaim.vue';
import KycNoVerificationMessage from '../components/AwesomeComponents/Account/Profile/KycNoVerificationMessage.vue';

export default {
  name: 'CreateClaim',
  components: {
    KycNoVerificationMessage,
    CreateClaimComponent,
  },
  computed: {
    ...mapGetters([
      'loading',
      'getProfile',
    ]),
    isProfileLoading() {
      return this.loading(USER_PROFILE) === 'loading';
    },
    mustGoThroughKyc() {
      return this.getProfile.is_kyc_required && !this.getProfile.is_kyc_confirmed;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content.kyc-no-confirmed-message {
  padding-bottom: 1rem;
}
</style>
